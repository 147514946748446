import React from 'react';
import { PATH_MOCKUP } from '../../routes/paths';
import { useAuthContext } from '../../auth/useAuthContext';

function NavDesktop() {
  const { logout } = useAuthContext();

  const handleLogout = async () => {
    try {
      if (logout) {
        await logout();
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div
      style={{
        paddingTop: '10px',
        width: '250px',
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'column',
      }}
    >
      <a href={PATH_MOCKUP.account} className="nav-item">
        Account
      </a>
      <a href={PATH_MOCKUP.create} className="nav-item">
        Create
      </a>
      <a href={PATH_MOCKUP.projects} className="nav-item">
        Projects
      </a>
      {/* <a href={PATH_MOCKUP.payment} className="nav-item">
        Payment
      </a> */}
      {/* eslint-disable-next-line */}
      <div className="nav-item" onClick={() => handleLogout()}>
        Log out
      </div>
    </div>
  );
}

export default NavDesktop;
