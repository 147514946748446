import PropTypes from 'prop-types';

import { useLocation } from 'react-router-dom';
import { Container, Stack, IconButton } from '@mui/material';
import { useAuthContext } from '../../auth/useAuthContext';
import { PATH_MOCKUP } from '../../routes/paths';

import Iconify from '../../components/iconify';

import useResponsive from '../../hooks/useResponsive';
import NavMobile from './mobile/NavMobile';

// ----------------------------------------------------------------------

Header.propTypes = {
  onOpenNav: PropTypes.func,
};

export default function Header({ onOpenNav }) {
  const { pathname } = useLocation();
  const { isAuthenticated } = useAuthContext();

  const isDesktop = useResponsive('up', 'lg');
  return (
    <Container
      sx={{
        height: '80px',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: isAuthenticated && isDesktop ? 'center' : 'space-between',
      }}
      maxWidth="xl"
    >
      {isAuthenticated && !isDesktop && (
        <Stack width={50} flexDirection="row" justifyContent="center">
          <IconButton onClick={onOpenNav} sx={{ color: 'text.primary' }}>
            <Iconify icon="eva:menu-2-fill" />
          </IconButton>
        </Stack>
      )}
      {(pathname === '/' || pathname === '/signup' || pathname === '/signin') &&
        !isAuthenticated && <Stack width={isDesktop ? 140 : 50}>&nbsp;</Stack>}
      <Stack>
        <a
          href={PATH_MOCKUP.root}
          className="normal-font"
          style={{
            fontSize: '48px',
            cursor: 'pointer',
            textDecoration: 'none',
            color: '#000000',
          }}
        >
          Boarder
        </a>
      </Stack>
      {(pathname === '/' || pathname === '/signup' || pathname === '/signin') &&
        // eslint-disable-next-line no-nested-ternary
        !isAuthenticated &&
        (isDesktop ? (
          <Stack flexDirection="row" width={140} justifyContent="space-between">
            <a
              href={PATH_MOCKUP.signin}
              className="normal-font"
              style={{
                fontSize: '16px',
                cursor: 'pointer',
                textDecoration: 'none',
                color: '#000000',
              }}
            >
              Login
            </a>
            <a
              href={PATH_MOCKUP.signup}
              className="normal-font"
              style={{
                fontSize: '16px',
                cursor: 'pointer',
                textDecoration: 'none',
                color: '#000000',
              }}
            >
              Sign Up
            </a>
          </Stack>
        ) : (
          <NavMobile />
        ))}
      {isAuthenticated && !isDesktop && <Stack width={50}>&nbsp;</Stack>}
    </Container>
  );
}
