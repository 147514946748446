import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
// @mui
import { Box, Drawer, Stack } from '@mui/material';
// hooks
import useResponsive from '../../hooks/useResponsive';
// config
import { NAV } from '../../config-global';
// components
import Scrollbar from '../../components/scrollbar';

import { useAuthContext } from '../../auth/useAuthContext';
//
// import navConfig from './config-navigation';
// import NavDocs from './NavDocs';
// import NavAccount from './NavAccount';
// import NavToggleButton from './NavToggleButton';

import { PATH_MOCKUP } from '../../routes/paths';

// ----------------------------------------------------------------------

NavVertical.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

export default function NavVertical({ openNav, onCloseNav }) {
  const { logout } = useAuthContext();
  const { pathname } = useLocation();

  const isDesktop = useResponsive('up', 'lg');

  const handleLogout = async () => {
    try {
      if (logout) {
        await logout();
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        mt: 5,
        height: 1,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Stack flexDirection="column">
        <Stack>
          <a href={PATH_MOCKUP.account} className="nav-item">
            Account
          </a>
        </Stack>
        <Stack>
          <a href={PATH_MOCKUP.create} className="nav-item">
            Create
          </a>
        </Stack>
        <Stack>
          <a href={PATH_MOCKUP.projects} className="nav-item">
            Projects
          </a>
        </Stack>
        {/* <Stack>
          <a href={PATH_MOCKUP.payment} className="nav-item">
            Payment
          </a>
        </Stack> */}
        <Stack>
          {/* eslint-disable-next-line */}
          <div className="nav-item" onClick={() => handleLogout()}>
            Log out
          </div>
        </Stack>
      </Stack>
      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.W_DASHBOARD },
      }}
    >
      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              zIndex: 0,
              width: NAV.W_DASHBOARD,
              bgcolor: 'transparent',
              borderRightStyle: 'dashed',
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: {
              width: NAV.W_DASHBOARD - 50,
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}
