// @mui
import { Box } from '@mui/material';
// ----------------------------------------------------------------------

export default function Footer() {
  return (
    <Box
      sx={{
        py: 2,
        position: 'fixed',
        bgcolor: 'background.default',
        bottom: 0,
        alignSelf: 'center',
        width: '100%',
        textAlign: 'center',
        height: '70px',
      }}
    >
      <span
        style={{
          textAlign: 'center',
          fontFamily: 'Courier Prime',
          fontSize: '20px',
          fontWeight: 400,
        }}
      >
        &copy;Boarder LLC 2024
      </span>
    </Box>
  );
}
