import PropTypes from 'prop-types';
import { Navigate, useParams } from 'react-router-dom';
// routes
import { PATH_MOCKUP } from '../routes/paths';

import { setResetPasswordSession } from './utils';

// ----------------------------------------------------------------------

SetPasswordGuard.propTypes = {
  children: PropTypes.node,
};

export default function SetPasswordGuard({ children }) {
  const { token } = useParams();

  if (!token) {
    return <Navigate to={PATH_MOCKUP.forgotPassword} />;
  }

  setResetPasswordSession(token);

  return <> {children} </>;
}
