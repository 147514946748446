const ROOTS_MOCKUP = '/';

// ----------------------------------------------------------------------

export const PATH_MOCKUP = {
  root: ROOTS_MOCKUP,
  signup: '/signup',
  signin: '/signin',
  resetPassword: (token) => `/auth/reset-password/${token}`,
  forgotPassword: '/auth/forgot-password',
  account: '/account',
  create: '/create',
  update: (id) => `/update/${id}`,
  projects: '/projects',
  payment: '/payment',
};
