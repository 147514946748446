import { Suspense, lazy } from 'react';
// components
import LoadingScreen from '../components/loading-screen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// ----------------------------------------------------------------------

// Mockup Pages
export const MockupHomePage = Loadable(lazy(() => import('../pages/mockup/HomePage')));

export const MockupSignupPage = Loadable(lazy(() => import('../pages/mockup/SignupPage')));
export const MockupSigninPage = Loadable(lazy(() => import('../pages/mockup/SigninPage')));
export const MockupForgotPasswordPage = Loadable(
  lazy(() => import('../pages/mockup/ForgotPasswordPage'))
);
export const MockupResetPasswordPage = Loadable(
  lazy(() => import('../pages/mockup/ResetPasswordPage'))
);

export const MockupAccountPage = Loadable(lazy(() => import('../pages/mockup/AccountPage')));
export const MockupCreatePage = Loadable(lazy(() => import('../pages/mockup/CreatePage')));
export const MockupUpdatePage = Loadable(lazy(() => import('../pages/mockup/UpdatePage')));
export const MockupProjectPage = Loadable(lazy(() => import('../pages/mockup/ProjectsPage')));
export const MockupPaymentPage = Loadable(lazy(() => import('../pages/mockup/PaymentPage')));
