import { Navigate, useRoutes } from 'react-router-dom';
// auth
import AuthGuard from '../auth/AuthGuard';
import GuestGuard from '../auth/GuestGuard';
import SetPasswordGuard from '../auth/SetPasswordGuard';
// layouts
import MockupLayout from '../layouts/mockup';
//
import {
  // Mockup elements
  MockupHomePage,
  MockupSignupPage,
  MockupAccountPage,
  MockupCreatePage,
  MockupUpdatePage,
  MockupPaymentPage,
  MockupProjectPage,
  MockupSigninPage,
  MockupForgotPasswordPage,
  MockupResetPasswordPage,
} from './elements';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      element: <MockupLayout />,
      children: [
        { element: <MockupHomePage />, index: true },
        {
          path: 'auth/forgot-password',
          element: <MockupForgotPasswordPage />,
        },
        {
          path: 'auth/reset-password/:token',
          element: (
            <SetPasswordGuard>
              <MockupResetPasswordPage />
            </SetPasswordGuard>
          ),
        },
        {
          path: 'signup',
          element: (
            <GuestGuard>
              <MockupSignupPage />
            </GuestGuard>
          ),
        },
        {
          path: 'signin',
          element: (
            <GuestGuard>
              <MockupSigninPage />
            </GuestGuard>
          ),
        },
        {
          path: 'account',
          element: (
            <AuthGuard>
              <MockupAccountPage />
            </AuthGuard>
          ),
        },
        {
          path: 'create',
          element: (
            <AuthGuard>
              <MockupCreatePage />
            </AuthGuard>
          ),
        },
        {
          path: 'update/:id',
          element: (
            <AuthGuard>
              <MockupUpdatePage />
            </AuthGuard>
          ),
        },
        {
          path: 'projects',
          element: (
            <AuthGuard>
              <MockupProjectPage />
            </AuthGuard>
          ),
        },
        {
          path: 'payment',
          element: (
            <AuthGuard>
              <MockupPaymentPage />
            </AuthGuard>
          ),
        },
      ],
    },

    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
