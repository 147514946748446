import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  images: [],
};

const slice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET EVENTS
    getImagesSuccess(state, action) {
      state.isLoading = false;
      state.images = action.payload;
    },

    createImageSuccess(state, action) {
      state.isLoading = false;
      state.images = [...state.images, action.payload];
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getImages() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/projects/');
      dispatch(slice.actions.getImagesSuccess(response.data.events));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createImage(prompts, project_id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      for (let index = 0; index < prompts.length; index += 1) {
        const prompt = prompts[index];
        const response = axios.post('/api/images/', {
          prompt,
          index,
          project_id,
        });
        dispatch(slice.actions.createImageSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
