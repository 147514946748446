import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
// @mui
import { Drawer, IconButton, Stack, Grid } from '@mui/material';
import { MenuOutlined } from '@mui/icons-material';
// config
import { NAV } from '../../../config-global';
// components
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';
//
import { PATH_MOCKUP } from '../../../routes/paths';
// ----------------------------------------------------------------------

NavMobile.propTypes = {
  data: PropTypes.array,
  isOffset: PropTypes.bool,
};

export default function NavMobile({ isOffset, data }) {
  const { pathname } = useLocation();

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (open) {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Stack width={50} flexDirection="row" justifyContent="center">
        <IconButton
          onClick={handleOpen}
          sx={{
            ...(isOffset && {
              color: 'text.primary',
            }),
          }}
        >
          <MenuOutlined />
        </IconButton>
      </Stack>

      <Drawer
        anchor="right"
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            pb: 5,
            width: NAV.W_BASE,
          },
        }}
      >
        <Scrollbar>
          <Stack justifyContent="center" sx={{ mb: '28px', px: '12px' }}>
            <Grid item sx={{ alignSelf: { xs: 'end', md: 'end' } }}>
              <IconButton color="inherit" edge="start" onClick={handleClose}>
                <Iconify icon="carbon:close-filled" />
              </IconButton>
            </Grid>
            <Stack
              display="flex"
              flexDirection="column"
              justifyContent="center"
              gap={2}
              sx={{ mt: 3, ml: 1 }}
            >
              <Stack>
                <a
                  href={PATH_MOCKUP.signin}
                  className="normal-font"
                  style={{
                    fontSize: '16px',
                    cursor: 'pointer',
                    textDecoration: 'none',
                    color: '#000000',
                  }}
                >
                  Login
                </a>
              </Stack>
              <Stack>
                <a
                  href={PATH_MOCKUP.signup}
                  className="normal-font"
                  style={{
                    fontSize: '16px',
                    cursor: 'pointer',
                    textDecoration: 'none',
                    color: '#000000',
                  }}
                >
                  Sign Up
                </a>
              </Stack>
            </Stack>
          </Stack>
        </Scrollbar>
      </Drawer>
    </>
  );
}
