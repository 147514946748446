import { useState } from 'react';
import { useLocation, Outlet } from 'react-router-dom';
// @mui
import { Box } from '@mui/material';
//

import useResponsive from '../../hooks/useResponsive';

import Footer from './Footer';
import Header from './Header';
import NavDesktop from './NavDesktop';
import NavVertical from './NavVertical';
import { useAuthContext } from '../../auth/useAuthContext';

// ----------------------------------------------------------------------

export default function MockupLayout() {
  const { pathname } = useLocation();
  const isDesktop = useResponsive('up', 'lg');
  const { isAuthenticated } = useAuthContext();

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const renderNavVertical = <NavVertical openNav={open} onCloseNav={handleClose} />;

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Header onOpenNav={handleOpen} />
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
        {pathname !== '/signup' &&
          pathname !== '/signin' &&
          isAuthenticated &&
          (isDesktop ? <NavDesktop /> : renderNavVertical)}
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: { xs: 2, md: 5 },
            marginBottom: '80px',
          }}
        >
          <Outlet />
        </Box>
      </Box>
      <Footer />
    </Box>
  );
}
